.App {
    min-height: 100vh;
    overflow: hidden;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-header {
    min-height: 10vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    font-weight: bold;
    color: rgb(0, 0, 0);
}

.App-body {
    background-color: white;
    min-width: 100%;
    min-height: 80vh;
    padding: 10px;
}
